<script>
import Layout from "../../layouts/main";
import * as moment from "moment";

import {
  RECON_DEATILS,
} from "@/state/actions.type";
import Breadcrumb from "../../../components/breadcrumb.vue";

export default {
  components: {
    Layout,
    Breadcrumb
  },
  data() {
    return {
      reconDetails: null
    }
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    dateTime(date) {
      return moment(date).format("dddd Do of MMMM YYYY HH:mm:ss A");
    },
    validationStatus(value) {
      let status = value;
      switch(value) {
        case 'FILE_UPLOAD_INGESTION_FAIL':
          status = 'Ingestion Failed';
          break;
        case 'FILE_UPLOAD_VALIDATION_FAIL':
          status = 'Validation Failed';
          break;
        case 'FILE_UPLOAD_VALIDATION_SUCCESS':
          status = 'Success';
          break;
        case 'FILE_UPLOAD_INITIATED':
          status = 'Initiated';
          break;
        default:
          break;
      }
      return status;
    }
  },

  created() {
    this.$store.dispatch(`reconciliation/${RECON_DEATILS}`, this.$route.query.ref).then((response) => {
      this.reconDetails = response.data;
    }).catch((error) => {
      console.log(error);
      this.$notify({
        type: "error",
        text: JSON.parse(error.message).message,
        closeOnClick: true
      });
    });
  },

  computed: {
    items() {
      return [
        {
          text: 'Upload Files',
          to: { name: 'Upload Files' }
        },
        {
          text: 'View Details',
          active: true
        }
      ];
    },
  },

  methods: {
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: "File ID copied",
        closeOnClick: true,
      });
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>
    <breadcrumb :items="items" :goBackTo="{ name: 'Upload Files' }" class="mb-2"/>
    <div v-if="reconDetails">
      <div class="my-3">
        <div class="section-header">
          <p class="section-title">Detailed File View</p>
        </div>
    
        <div class="details-table">
          <p class="my-auto">File Name</p>
          <a :href="reconDetails.file_path" target="_black" class="my-auto text-chai">{{ reconDetails.file_name }}</a>
        </div>
    
        <div class="details-table">
          <p class="my-auto">Validation Status</p>
          <div
            class="my-auto badge custom-status"
            style="text-transform: capitalize; color: #000; font-weight: 350;"
            :class="{
              'badge-soft-danger': reconDetails.status === 'FILE_UPLOAD_INGESTION_FAIL' || reconDetails.status === 'FILE_UPLOAD_VALIDATION_FAIL',
              'badge-soft-success': reconDetails.status === 'FILE_UPLOAD_VALIDATION_SUCCESS',
              'badge-soft-warning': reconDetails.status === 'FILE_UPLOAD_INITIATED'
            }"
          >
            <svg v-if="reconDetails.status === 'FILE_UPLOAD_VALIDATION_SUCCESS'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-2">
              <path d="M4.54115 6.73066C4.31236 6.4986 3.93977 6.4986 3.70772 6.72739C3.47567 6.95617 3.47567 7.32876 3.70445 7.56082L6.49562 10.3716C6.60674 10.4827 6.75382 10.5448 6.9107 10.5448C6.9107 10.5448 6.9107 10.5448 6.91397 10.5448C7.07085 10.5448 7.21793 10.4827 7.32905 10.3749L15.8268 1.92292C16.0555 1.69413 16.0588 1.32154 15.83 1.08949C15.6012 0.857437 15.2286 0.857437 14.9966 1.08622L11.993 4.07022C10.7151 2.49815 8.79327 1.57647 6.75055 1.57647C5.79619 1.57647 4.87452 1.77257 4.01168 2.15497C3.17825 2.52756 2.43633 3.05377 1.81208 3.72705C1.18782 4.39706 0.713913 5.17166 0.40342 6.02796C0.0798535 6.92022 -0.0508804 7.85824 0.0177549 8.81913C0.249808 12.143 2.93312 14.8296 6.25703 15.0682C6.42045 15.0813 6.58713 15.0846 6.75055 15.0846C8.29648 15.0846 9.79012 14.5616 10.9929 13.5877C12.3067 12.5287 13.1696 11.0285 13.4278 9.36821C13.4801 9.02831 13.5062 8.67859 13.5062 8.32888C13.5062 8.00531 13.2415 7.74058 12.9179 7.74058C12.5944 7.74058 12.3296 8.00531 12.3296 8.32888C12.3296 8.61649 12.3067 8.90411 12.2643 9.18845C11.8263 12.0319 9.22469 14.0975 6.34201 13.8916C3.59986 13.6955 1.38392 11.4796 1.19109 8.73415C1.07997 7.16535 1.60617 5.67171 2.67165 4.52779C3.72406 3.40021 5.21116 2.75308 6.75055 2.75308C8.47624 2.75308 10.0973 3.55382 11.153 4.90692L6.91724 9.12309L4.54115 6.73066Z" fill="#219653"/>
            </svg>
            <svg v-else-if="reconDetails.status === 'FILE_UPLOAD_INGESTION_FAIL'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-2">
              <path d="M8.00065 14.6663C11.6673 14.6663 14.6673 11.6663 14.6673 7.99967C14.6673 4.33301 11.6673 1.33301 8.00065 1.33301C4.33398 1.33301 1.33398 4.33301 1.33398 7.99967C1.33398 11.6663 4.33398 14.6663 8.00065 14.6663Z" stroke="#C84C62" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 11L11 5" stroke="#C84C62" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 11L5 5" stroke="#C84C62" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else-if="reconDetails.status === 'FILE_UPLOAD_INITIATED'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" class="mr-2">
              <path d="M5.51315 12.6412C3.01065 11.982 1.16732 9.70699 1.16732 7.00033C1.16732 3.78033 3.75732 1.16699 7.00065 1.16699C10.8915 1.16699 12.834 4.41033 12.834 4.41033M12.834 4.41033V1.75033M12.834 4.41033H11.6615H10.244" stroke="#FFA500" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.834 7C12.834 10.22 10.2207 12.8333 7.00065 12.8333" stroke="#FFA500" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
            </svg>
            <svg v-else-if="reconDetails.status === 'FILE_UPLOAD_VALIDATION_FAIL'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-2">
              <path d="M8.00065 14.6663C11.6673 14.6663 14.6673 11.6663 14.6673 7.99967C14.6673 4.33301 11.6673 1.33301 8.00065 1.33301C4.33398 1.33301 1.33398 4.33301 1.33398 7.99967C1.33398 11.6663 4.33398 14.6663 8.00065 14.6663Z" stroke="#DE3535" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 5.33301V8.66634" stroke="#DE3535" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.99609 10.667H8.00208" stroke="#DE3535" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>{{ reconDetails.status | validationStatus }}
          </div>
        </div>
    
        <div class="details-table">
          <p class="my-auto">Records Date Range</p>
          <p class="my-auto">{{ reconDetails.min_date | date }} - {{ reconDetails.max_date | date }}</p>
        </div>
    
        <div class="details-table">
          <p class="my-auto">No. of Records</p>
          <p class="my-auto">{{ reconDetails.total_records }}</p>
        </div>
    
        <!-- <div class="details-table">
          <p class="my-auto">Total Amount</p>
          <p class="my-auto">{{ reconDetails.currency ? reconDetails.total_amount.toLocaleString('en-US', {style : 'currency', currency : reconDetails.currency}) : reconDetails.total_amount }}</p>
        </div> -->
    
        <div class="details-table">
          <p class="my-auto">File ID</p>
          <div class="my-auto">
            {{ reconDetails.ref }}
            <b-btn size="sm" v-clipboard:copy="reconDetails.ref" v-clipboard:success="onCopySuccess" class="ml-3" style="border-radius: var(--8, 8px); background: rgb(217,217,217, 0.3); border: none; width: 32px; height: 32px; cursor: copy;">
              <img src="@/assets/images/copy.svg" style="z-index: 2;" height="16" alt="copy">
            </b-btn>
          </div>
        </div>
    
        <div class="details-table">
          <p class="my-auto">File Upload Date</p>
          <p class="my-auto">{{ reconDetails.CreatedAt | dateTime }}</p>
        </div>
    
        <div class="details-table">
          <p class="my-auto">Data Source</p>
          <p class="my-auto">{{ reconDetails.DataSource.display_name }}</p>
        </div>

        <div class="details-table">
          <p class="my-auto">Source</p>
          <p class="my-auto">{{ reconDetails.source }}</p>
        </div>
      </div>
  
      <div v-if="reconDetails.error" class="mt-3">
        <div class="section-header">
          <p class="section-title">Error Details</p>
        </div>
  
        <p style="padding-left: 18px; padding-top: 14px; color: rgba(0, 0, 0, 0.50); font-size: 14px; line-height: 20px;">{{ reconDetails.error }}</p>
  
        <div class="mt-3 d-flex" style="gap: 24px;" v-if="reconDetails.DataSource.category !== 'MARKETPLACE'">
          <div class="flex-fill" style="border-radius: 12px; border: 1px solid #DCDAD5; padding: 16px 18px; line-height: 20px;">
            <p class="mb-2" style="color: rgba(0, 0, 0, 0.50); font-size: 12px;">Duplicate Entry</p>
            <p class="mb-0">{{ reconDetails.validation_result.duplicate_errro_count }}</p>
          </div>
          <div class="flex-fill" style="border-radius: 12px; border: 1px solid #DCDAD5; padding: 16px 18px; line-height: 20px;">
            <p class="mb-2" style="color: rgba(0, 0, 0, 0.50); font-size: 12px;">Validation Errors</p>
            <p class="mb-0">{{ reconDetails.validation_result.validation_error_count }}</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.section-header {
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid #DCDAD5;
}
.details-table {
  border-bottom: 1px solid rgba(220, 218, 213, 0.30);
  height: 48px;
  display: flex;
  > :first-child {
    width: 30%;
    padding-left: 18px;
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
  }
  > :last-child {
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
  }
}
</style>